import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { CredentialsHandler } from "../definitions";
import { version } from "../../package.json";

interface LoginScreenProps {
  onLogin(): Promise<void>;
  isWorking: boolean;
  setIsWorking(isWorking: boolean): void;
}

export function LoginScreen(props: LoginScreenProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function tryLogin() {
    try {
      await CredentialsHandler.login(email, password);
      await props.onLogin();
    } catch (error) {
      alert((error as Error).message);
    }
  }

  return (
    <div>
      <form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 120,
          }}
        >
          <div style={{ fontSize: 32, marginBottom: 36 }}>Kasse 1</div>
          <TextField
            value={email}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            label="CKAL email"
            style={{ marginBottom: 24 }}
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            label="Password"
            autoComplete="current-password"
          />

          <Button
            variant="contained"
            style={{ marginTop: 24 }}
            disabled={props.isWorking}
            onClick={async () => {
              try {
                props.setIsWorking(true);
                await tryLogin();
              } catch (error) {
                alert((error as Error).message);
              } finally {
                props.setIsWorking(false);
              }
            }}
          >
            {props.isWorking ? "..." : "Log ind"}
          </Button>
        </div>
      </form>
      <div
        style={{ textAlign: "center", marginTop: 92, color: "rgba(0,0,0,0.3)" }}
      >
        {version}
      </div>
    </div>
  );
}
