import { FaChevronRight } from "react-icons/fa";
import { EMAIL_CASPER, EMAIL_CECILIE, EMAIL_TO_USER } from "../definitions";
import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Avatar } from "./Avatar";
import CountUp from "react-countup";
import {
  Kasse1Email,
  Transaction,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";

interface StatusProps {
  transactions: Transaction[];
  isFetching: boolean;
  onUserSelect(user: Kasse1Email): void;
}

const EASE_IN_DURATION = 2;

export function Status(props: StatusProps) {
  const firstRender = useRef(true);
  const [casperTotalPrevious, setCasperTotalPrevious] = useState(0);
  const [cecilieTotalPrevious, setCecilieTotalPrevious] = useState(0);
  const [casperPending, setCasperPending] = useState<Transaction[]>([]);
  const [ceciliePending, setCeciliePending] = useState<Transaction[]>([]);

  useEffect(() => {
    setCasperPending((curr) => {
      const newTransactions = props.transactions.filter(
        (t) =>
          EMAIL_TO_USER[t.user as keyof typeof EMAIL_TO_USER] === "Casper" &&
          t.pending
      );

      if (JSON.stringify(curr) !== JSON.stringify(newTransactions)) {
        return newTransactions;
      }

      return curr;
    });
  }, [props.transactions]);

  useEffect(() => {
    setCeciliePending((curr) => {
      const newTransactions = props.transactions.filter(
        (t) =>
          EMAIL_TO_USER[t.user as keyof typeof EMAIL_TO_USER] === "Cecilie" &&
          t.pending
      );

      if (JSON.stringify(curr) !== JSON.stringify(newTransactions)) {
        return newTransactions;
      }

      return curr;
    });
  }, [props.transactions]);

  const casperTotal = useMemo(
    () => casperPending.reduce((sum, curr) => sum + curr.amount, 0),
    [casperPending]
  );

  const cecilieTotal = useMemo(
    () => ceciliePending.reduce((sum, curr) => sum + curr.amount, 0),
    [ceciliePending]
  );

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    setTimeout(() => {
      firstRender.current = false;
      setCasperTotalPrevious(casperTotal);
      setCecilieTotalPrevious(cecilieTotal);
    }, EASE_IN_DURATION * 1000);
  }, [casperTotal, cecilieTotal]);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }

    setCasperTotalPrevious(
      casperPending.slice(1).reduce((sum, curr) => sum + curr.amount, 0)
    );
  }, [casperPending]);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }

    setCecilieTotalPrevious(
      ceciliePending.slice(1).reduce((sum, curr) => sum + curr.amount, 0)
    );
  }, [ceciliePending]);

  return (
    <div
      style={{
        background: "white",
        borderRadius: 8,
        border: "1px solid rgba(0,0,0,0.1)",
        margin: "24px 12px 12px 12px",
        color: "rgba(0,0,0,0.7)",
        fontSize: 20,
      }}
    >
      <div
        style={{
          padding: "12px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => props.onUserSelect(EMAIL_CASPER)}
      >
        <div style={{ display: "flex", alignItems: "center", columnGap: 12 }}>
          <Avatar
            size={32}
            email="thisbecasper@gmail.com"
            firstname="Casper"
            lastname="Laursen"
          />
          <div>Casper</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
          <div style={{ textAlign: "end", whiteSpace: "nowrap" }}>
            {props.isFetching && props.transactions.length === 0 ? (
              <CircularProgress
                size={20}
                style={{ marginRight: 4, marginBottom: -2 }}
              />
            ) : (
              <div>
                {casperTotal === 0 ? "" : "-"}
                <CountUp
                  start={casperTotalPrevious}
                  end={casperTotal}
                  duration={EASE_IN_DURATION}
                />
                {" kr."}
              </div>
            )}
          </div>
          <FaChevronRight style={{ fontSize: 16 }} />
        </div>
      </div>
      <div style={{ borderTop: "1px solid rgba(0,0,0,0.1)" }} />
      <div
        style={{
          padding: "12px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => props.onUserSelect(EMAIL_CECILIE)}
      >
        <div style={{ display: "flex", alignItems: "center", columnGap: 12 }}>
          <Avatar
            size={32}
            email="ceciliefdahlgaard@gmail.com"
            firstname="Cecilie"
            lastname="Dahlgaard"
          />
          <div>Cecilie</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
          <div style={{ textAlign: "end", whiteSpace: "nowrap" }}>
            {props.isFetching && props.transactions.length === 0 ? (
              <CircularProgress
                size={20}
                style={{ marginRight: 4, marginBottom: -2 }}
              />
            ) : (
              <div>
                {cecilieTotal === 0 ? "" : "-"}
                <CountUp
                  start={cecilieTotalPrevious}
                  end={cecilieTotal}
                  duration={EASE_IN_DURATION}
                />
                {" kr."}
              </div>
            )}
          </div>
          <FaChevronRight style={{ fontSize: 16 }} />
        </div>
      </div>
    </div>
  );
}
