import { CKALCredentialsHandler, UserInfo } from "@ckal-software/ckal-lib";

export const EMAIL_CASPER = "thisbecasper@gmail.com";
export const EMAIL_CECILIE = "ceciliefdahlgaard@gmail.com";

export type Email = typeof EMAIL_CASPER | typeof EMAIL_CECILIE;

export const EMAIL_TO_USER: Record<Email, "Casper" | "Cecilie"> = {
  [EMAIL_CASPER]: "Casper",
  [EMAIL_CECILIE]: "Cecilie",
};

export const CredentialsHandler = new CKALCredentialsHandler("kasse1");

const compose = (...regexes: RegExp[]) =>
  new RegExp(regexes.map((regex) => regex.source).join("|"));
export const REGEX_EMPTY = new RegExp(/^$/);
export const REGEX_POSITIVE_INTEGER = new RegExp(/^[1-9]\d{0,10}$/);
export const REGEX_POSITIVE_INTEGER_OR_EMPTY = compose(
  REGEX_POSITIVE_INTEGER,
  REGEX_EMPTY
);

export type UserInfoKasse1 = Omit<UserInfo, "email"> & {
  email: typeof EMAIL_CASPER | typeof EMAIL_CECILIE;
};
