import { Button, Dialog, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  DeleteTransactionEndpoint,
  Transaction,
  UpdateTransactionEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { callEndpoint } from "../util";

interface EditModalProps {
  transaction: Transaction;
  refresh(): void;
  onClose(): void;
}

export function EditModal(props: EditModalProps) {
  const [value, setValue] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!props.transaction) {
      return;
    }

    setValue(props.transaction.description);
  }, [props.transaction]);

  async function updateDescription() {
    const [err] = await callEndpoint(
      UpdateTransactionEndpoint,
      {
        id: props.transaction?._id,
        description: value,
      },
      { setWorking: setIsUpdating }
    );

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    props.refresh();
    props.onClose();
    setValue("");
  }

  async function deleteTransaction() {
    const [err] = await callEndpoint(
      DeleteTransactionEndpoint,
      {
        id: props.transaction._id,
      },
      { setWorking: setIsDeleting }
    );

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    props.refresh();
    props.onClose();
  }

  const isTransfer = useMemo(
    () => props.transaction && props.transaction.amount < 0,
    [props.transaction]
  );

  return (
    <Dialog onClose={props.onClose} open={!!props.transaction} fullWidth>
      <div style={{ padding: 24 }}>
        {isTransfer && (
          <div style={{ marginBottom: 24, color: "rgba(0, 0, 0, 0.7)" }}>
            Fjern overførsel?
          </div>
        )}
        {!isTransfer && (
          <TextField
            label="Beskrivelse"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            multiline
            autoFocus
            style={{ width: "100%" }}
          />
        )}
        <div
          style={{
            display: "flex",
            marginTop: 16,
            justifyContent: "space-between",
          }}
        >
          {isTransfer && <div />}
          <Button
            variant="contained"
            onClick={deleteTransaction}
            color="error"
            disabled={isDeleting}
          >
            {isDeleting ? "..." : "Fjern"}
          </Button>
          {!isTransfer && (
            <Button
              variant="contained"
              onClick={updateDescription}
              disabled={isUpdating}
            >
              {isUpdating ? "..." : "OK"}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}
