export function WelcomeScreen() {
  return (
    <div
      style={{
        fontSize: 60,
        textAlign: "center",
        marginTop: 280,
        fontWeight: "bold",
        color: "rgba(0,0,0,0.7)",
      }}
    >
      <img src="logo.png" alt="" style={{ height: 200 }} />
    </div>
  );
}
