import { useMemo, useState } from "react";
import { EMAIL_TO_USER } from "../definitions";
import { callEndpoint, toDate, useIsPermissionGranted } from "../util";
import { EditModal } from "./EditModal";
import { FaChevronLeft } from "react-icons/fa";
import { Button } from "@mui/material";
import { TransferModal } from "./TransferModal";
import {
  Clearance,
  DeleteLatestClearanceEndpoint,
  Kasse1Email,
  Transaction,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { version } from "../../package.json";
import { UserInfo } from "@ckal-software/ckal-lib";

interface HistoryProps {
  userInfo: UserInfo;
  selectedUser: Kasse1Email;
  transactions: Transaction[];
  clearances: Clearance[];
  onBack(): void;
  refresh(): void;
  onLogout(): void;
}

export function History(props: HistoryProps) {
  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  const [count, setCount] = useState(10);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const selectedTransaction = useMemo(
    () => props.transactions.find((t) => t._id === selectedTransactionId),
    [selectedTransactionId, props.transactions]
  );

  const isPermissionGranted = useIsPermissionGranted();

  const totalAmount = useMemo(
    () =>
      props.transactions.reduce(
        (sum, t) => sum + (t.pending ? t.amount : 0),
        0
      ),
    [props.transactions]
  );

  const latestClearance = useMemo<Clearance | undefined>(
    () =>
      props.clearances.sort((a, b) =>
        b.timestamp.localeCompare(a.timestamp)
      )[0],
    [props.clearances]
  );

  const latestTransfer = useMemo<Transaction | undefined>(
    () =>
      props.transactions
        .filter((t) => t.amount < 0)
        .sort((a, b) => b.timestamp.localeCompare(a.timestamp))[0],
    [props.transactions]
  );

  const useTransfer = useMemo(
    () =>
      (latestTransfer?.timestamp || "") > (latestClearance?.timestamp || ""),
    [latestClearance, latestTransfer]
  );

  return (
    <div style={{ margin: 8 }}>
      {selectedTransaction && (
        <EditModal
          refresh={props.refresh}
          onClose={() => setSelectedTransactionId("")}
          transaction={selectedTransaction}
        />
      )}
      {showTransferModal && (
        <TransferModal
          credit={totalAmount}
          onClose={() => setShowTransferModal(false)}
          refresh={props.refresh}
          user={props.selectedUser}
        />
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          fontSize: 24,
          textAlign: "center",
          marginBottom: 24,
          alignItems: "center",
          marginTop: 24,
        }}
        onClick={props.onBack}
      >
        <FaChevronLeft style={{ fontSize: 24, marginLeft: 18 }} />
        <div>Historik</div>
      </div>
      {props.selectedUser === props.userInfo.email && (
        <div style={{ display: "flex", justifyContent: "center", margin: 8 }}>
          <Button onClick={() => setShowTransferModal(true)}>
            Registrer overført
          </Button>
        </div>
      )}
      <div
        style={{
          marginBottom: 16,
          color: "rgba(0,0,0,0.7)",
          textAlign: "center",
        }}
      >{`${totalAmount} kr. mangler at blive overført`}</div>
      {(latestClearance || latestTransfer) && (
        <div
          style={{
            marginBottom: 16,
            fontSize: 14,
            fontStyle: "italic",
            color: "rgba(0,0,0,0.55)",
            textAlign: "center",
          }}
          onClick={async () => {
            if (useTransfer) {
              return;
            }

            if (window.confirm("Vil du fjerne seneste overførsel")) {
              const [err] = await callEndpoint(DeleteLatestClearanceEndpoint);

              if (err) {
                alert(`Der skete en fejl: ${err.error}`);
                return;
              }

              props.refresh();
            }
          }}
        >{`Sidst ${useTransfer ? "delvist" : ""} overført d. ${toDate(
          useTransfer ? latestTransfer?.timestamp : latestClearance?.timestamp
        )} ${
          useTransfer
            ? `(${Math.abs(latestTransfer?.amount || 0)} ud af ${
                totalAmount + Math.abs(latestTransfer?.amount || 0)
              } kr.)`
            : ""
        }`}</div>
      )}
      <div
        style={{
          background: "white",
          fontSize: 16,
          color: "rgba(0,0,0,0.7)",
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {props.transactions.slice(0, count).map((t, i) => (
          <div
            key={t._id}
            style={{
              borderBottom:
                i === props.transactions.length - 1
                  ? undefined
                  : "1px solid rgba(0,0,0,0.1)",
              padding: 16,
              display: "grid",
              gridTemplateColumns: "108px auto 68px",
              alignItems: "center",
              columnGap: 16,
              position: "relative",
            }}
            onClick={() => setSelectedTransactionId(t._id)}
          >
            {t.pending && (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: 2,
                  background: "#0098ff",
                }}
              />
            )}
            {t.user !== t.addedBy && (
              <div
                style={{
                  fontStyle: "italic",
                  fontSize: 10,
                  marginTop: 4,
                  position: "absolute",
                  left: 16,
                  bottom: 4,
                  color: "rgba(0,0,0,0.55)",
                }}
              >
                Added by{" "}
                {EMAIL_TO_USER[t.addedBy as keyof typeof EMAIL_TO_USER]}
              </div>
            )}
            <div style={{ whiteSpace: "nowrap" }}>{toDate(t.timestamp)}</div>
            <div
              style={{
                margin: "-12px 0",
                fontSize: 14,
                fontStyle: "italic",
                color: "rgba(0,0,0,0.55)",
              }}
            >
              {t.description}
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                textAlign: "end",
                color: t.amount < 0 ? "#51b135" : undefined,
              }}
            >
              {t.amount < 0 ? "" : "-"}
              {Math.abs(t.amount)} kr.
            </div>
          </div>
        ))}
      </div>
      {count < props.transactions.length && (
        <div
          style={{
            color: "#489fd7",
            textAlign: "center",
            marginTop: 18,
          }}
          onClick={() => setCount((c) => c + 20)}
        >
          Vis flere
        </div>
      )}
      <div
        style={{
          color: "#489fd7",
          textAlign: "center",
          marginTop: 100,
        }}
        onClick={() => window.location.reload()}
      >
        {version}
      </div>
      <div
        style={{
          color: "#489fd7",
          textAlign: "center",
          marginTop: 24,
        }}
        onClick={props.onLogout}
      >
        Log ud
      </div>
      <div style={{ marginTop: 36, textAlign: "center", opacity: 0.5 }}>
        Notfications are {isPermissionGranted ? "on" : "off"}
      </div>
      <div style={{ height: 100 }} />
    </div>
  );
}
