import { useEffect, useState } from "react";
import { CredentialsHandler } from "./definitions";
import {
  getCallInternalEndpointFunction,
  getWebPushPermissionState,
} from "@ckal-software/ckal-lib";

export function toDate(timestamp: string | undefined) {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  const month = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ][date.getMonth()];

  return `${date.getDate()}. ${month}`;
}

export async function getHeaders(customHeaders?: Record<string, string>) {
  const headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", await CredentialsHandler.getAccessToken());

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([k, v]) => headers.set(k, v));
  }

  return headers;
}

export const callEndpoint = getCallInternalEndpointFunction(
  CredentialsHandler.getAccessToken
);

export function useIsPermissionGranted() {
  const [granted, setGranted] = useState(false);

  useEffect(() => {
    getWebPushPermissionState().then((state) =>
      setGranted(state === "granted")
    );
  }, []);

  return granted;
}
