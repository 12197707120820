import { useEffect, useState } from "react";
import { LoginScreen } from "./components/LoginScreen";
import { Main } from "./components/Main";
import { WelcomeScreen } from "./components/WelcomeScreen";
import { CredentialsHandler, UserInfoKasse1 } from "./definitions";

export function App() {
  const [userInfo, setUserInfo] = useState<UserInfoKasse1>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isFirstLoginTry, setIsFirstLoginTry] = useState(true);

  useEffect(() => {
    updateUserInfo();
  }, []);

  async function updateUserInfo() {
    setIsGettingUserInfo(true);
    try {
      setUserInfo((await CredentialsHandler.getUserInfo()) as UserInfoKasse1);
      // eslint-disable-next-line no-empty
    } catch {}

    setIsGettingUserInfo(false);
  }

  return (
    <div style={{ height: "100%" }}>
      {isGettingUserInfo && isFirstLoginTry && !isWorking ? (
        <WelcomeScreen />
      ) : (
        <>
          {!userInfo ? (
            <LoginScreen
              onLogin={updateUserInfo}
              isWorking={isWorking}
              setIsWorking={setIsWorking}
            />
          ) : (
            <Main
              userInfo={userInfo}
              onLogout={async () => {
                setIsFirstLoginTry(false);
                await CredentialsHandler.clearCredentials();
                setUserInfo(undefined);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
