import { Status } from "./Status";
import { Keypad } from "./Keypad";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { History } from "./History";
import { callEndpoint } from "../util";
import {
  Clearance,
  ClearancesEndpoint,
  Kasse1Email,
  Transaction,
  TransactionsEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { UserInfoKasse1 } from "../definitions";
import { ApplicationName, sendPushSubscription } from "@ckal-software/ckal-lib";

interface MainProps {
  userInfo: UserInfoKasse1;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [clearances, setClearances] = useState<Clearance[]>([]);
  const [selectedUser, setSelectedUser] = useState<Kasse1Email>();
  const [hasFetchedOnce, setHasFetchedOnce] = useState(false);

  const selectedTransactions = useMemo(
    () => transactions.filter((t) => t.user === selectedUser),
    [selectedUser, transactions]
  );

  const selectedClearances = useMemo(
    () => clearances.filter((c) => c.email === selectedUser),
    [selectedUser, clearances]
  );

  useEffect(() => {
    if ("Notification" in window && navigator.serviceWorker) {
      Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          sendPushSubscription(callEndpoint, ApplicationName.Kasse1);
        }
      });
    }
  }, []);

  const refresh = useCallback(() => {
    fetchTransactions();
    fetchClearances();
  }, []);

  const toggleRef = useRef(false);

  useEffect(() => {
    refresh();

    function refreshOnVisibilityChange() {
      if (toggleRef.current) {
        refresh();
      }
      toggleRef.current = !toggleRef.current;
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        refreshOnVisibilityChange
      );
    };
  }, [refresh]);

  async function fetchTransactions() {
    const [err, resultTransactions] = await callEndpoint(
      TransactionsEndpoint,
      undefined,
      { setWorking: setIsFetchingTransactions }
    );
    setHasFetchedOnce(true);

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    setTransactions(resultTransactions);
  }

  async function fetchClearances() {
    const [err, data] = await callEndpoint(ClearancesEndpoint);

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    setClearances(data);
  }

  return (
    <div>
      {selectedTransactions.length > 0 && selectedUser ? (
        <History
          userInfo={props.userInfo}
          selectedUser={selectedUser}
          clearances={selectedClearances}
          transactions={selectedTransactions}
          onBack={() => setSelectedUser(undefined)}
          refresh={() => {
            fetchTransactions();
            fetchClearances();
          }}
          onLogout={props.onLogout}
        />
      ) : (
        <div
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Status
            onUserSelect={setSelectedUser}
            isFetching={!hasFetchedOnce && isFetchingTransactions}
            transactions={transactions}
          />
          <Keypad email={props.userInfo.email} refresh={fetchTransactions} />
        </div>
      )}
    </div>
  );
}
